<template>
    <div class="auth-main">
        <AuthLeftSideComponent/>
        <div class="auth-right">
            <div class="right-main d-flex flex-column h-100">
                <!-- auth header -->
                <RightHeaderComponent/>

                <!-- dynamic content section -->
                <slot></slot>
                
                <!-- right footer -->
                <RightFooterComponent/>
            </div>
        </div> 
    </div>
</template>
<script>
export default {
    name:'AuthLayout',
    components: {
        AuthLeftSideComponent: () => import('@/components/admin/layouts/auth_left/AuthLeftSideComponent.vue'),
        RightHeaderComponent: () => import('@/components/admin/layouts/auth_right_header/RightHeaderComponent.vue'),
        RightFooterComponent: () => import('@/components/admin/layouts/auth_right_footer/RightFooterComponent.vue')
    }
}
</script>
